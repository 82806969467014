import qs from "qs";
const idCard = require("idcard");

const shopHost = process.env.VUE_APP_BASE_WEB_SHOP_HOST;

export function setToken(token) {
  window.localStorage.setItem("token", token);
}

export function getToken() {
  return window.localStorage.getItem("token") || "";
}

export function formatNum2Str(num) {
  if (!num) {
    return "0.00";
  }

  const price = (num + "").split(".");
  price[1] = price[1] ? `${(price[1] + "000").substring(0, 2)}` : "00";
  return price.join(".");
}

// 跳登录页
export function login(url) {
  let _url = url;
  if (!_url) {
    _url = window.location.href;
  }

  // window.wx.miniProgram.postMessage({ data: { type: "login" } });
  // window.wx.miniProgram.navigateTo({ url: `/pages/login/index?url=${encodeURIComponent(_url)}` });
  window.wx.miniProgram.redirectTo({ url: `/pages/login/index?url=${encodeURIComponent(_url)}` });
}

// 跳转小程序首页
export function toMpHomePage() {
  window.wx.miniProgram.reLaunch({ url: "/pages/index/index" });
}

// 跳转审核结果页（重定向）
export function toMpAuthResultPage() {
  const _url = encodeURIComponent(window.location.origin + "/auth-result");
  const url = `/pages/webview/index?url=${_url}`;
  window.wx.miniProgram.reLaunch({ url: url });
}

// 跳转小程序营销页
export function toMarketingPage() {
  const url = `/pages/share/index?type=1`;
  window.wx.miniProgram.navigateTo({ url: url });
}

// 跳转小程序预览 PDF
export function toMpPreviewPdfPage(pdfUrl) {
  const params = {
    url: pdfUrl
  };
  const _url = encodeURIComponent(window.location.origin + "/preview-pdf?" + qs.stringify({ ...params }));
  const url = `/pages/webview/index?url=${_url}`;
  window.wx.miniProgram.navigateTo({ url: url });
}

// 跳转到商城列表页
export function toShopListPage() {
  const params = {};
  const _url = encodeURIComponent(shopHost + "/Product?" + qs.stringify({ ...params }));
  const url = `/pages/webview/index?url=${_url}`;
  window.wx.miniProgram.navigateTo({ url: url });
}

// 详细地址说明 https://www.npmjs.com/package/idcard
// 身份证校验 boolean
export function isCardId(t) {
  return idCard.verify(t);
}
// 身份证详细信息
export function isCardIdInfo(t) {
  return idCard.info(t);
}

// 时间转换
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}
