import "amfe-flexible";
import "./tw";
import { createApp } from "vue";
import Vant from "vant";
import qs from "qs";
import "vant/lib/index.less";
import "@/style/index.less";
import App from "./App.vue";
import router from "./router";
import BaseLayout from "@/components/BaseLayout.vue";
import { setToken } from "@/utils";

try {
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (token) {
    setToken(token);
  }
} catch (error) {
  console.error("参数错误：", error);
}

const app = createApp(App);
app.use(Vant);
app.component(BaseLayout.name, BaseLayout);
app.use(router).mount("#app");

const baseURL = process.env.VUE_APP_BASE_API;
console.log(`API baseURL：${baseURL}`);
