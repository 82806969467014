<template>
  <!-- <router-view /> -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component v-if="$route.meta.keepAlive" :is="Component" :key="$route.name" />
    </keep-alive>
    <component v-if="!$route.meta.keepAlive" :is="Component" :key="$route.name" />
  </router-view>
</template>

<style></style>
