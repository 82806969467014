import tw from "@xyy-supply/skynet-sdk";

const pid = process.env.VUE_APP_TW_PID;

// 天网初始化
if (pid) {
  tw.set({
    pid: pid,
    dev_mode: false
  });
}
