<template>
  <div class="layout">
    <!-- 固定顶部 -->
    <div v-if="$slots.fixedTop" class="layout--fixed-top">
      <slot name="fixedTop" />
    </div>

    <div class="layout--main">
      <slot />
    </div>

    <!-- 固定底部 -->
    <div v-if="$slots.fixed" class="layout--fixed-bottom">
      <slot name="fixed" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseLayout"
};
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &--main {
    flex: 1;
    box-sizing: border-box;
    overflow-y: auto;
  }

  &--fixed-top {
    flex-shrink: 0;
  }

  &--fixed-bottom {
    flex-shrink: 0;
    padding: 5px 6px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.06);
  }
}
</style>
