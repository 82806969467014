import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/home"),
    meta: { keepAlive: true }
  },

  {
    // 订单页
    path: "/orders",
    name: "Orders",
    component: () => import(/* webpackChunkName: "orders" */ "@/views/orders")
    // meta: { keepAlive: true }
  },

  {
    // 我的钱包
    path: "/wallet",
    name: "Wallet",
    component: () => import(/* webpackChunkName: "wallet" */ "@/views/wallet")
    // meta: { keepAlive: true }
  },

  {
    // 我的钱包 银行卡绑定
    path: "/wallet-card",
    name: "WalletCard",
    component: () => import(/* webpackChunkName: "wallet-card" */ "@/views/wallet-card")
    // meta: { keepAlive: true }
  },

  {
    // 文案：佣金规则
    path: "/text-commission-rules",
    name: "TextCommissionRules",
    component: () => import(/* webpackChunkName: "text-commission-rules" */ "@/views/text-commission-rules2")
    // meta: { keepAlive: true }
  },

  {
    // PDF 预览
    path: "/preview-pdf",
    name: "preview-pdf",
    component: () => import(/* webpackChunkName: "preview-pdf" */ "@/views/preview-pdf")
  },

  {
    // 加入荷保保
    path: "/add-panel",
    name: "AddPanel",
    component: () => import(/* webpackChunkName: "order" */ "@/views/addPanel")
  },

  {
    // 身份验证
    path: "/authentication",
    name: "Authentication",
    component: () => import(/* webpackChunkName: "order" */ "@/views/authentication")
  },

  {
    // 审核结果
    path: "/auth-result",
    name: "AuthResult",
    component: () => import(/* webpackChunkName: "order" */ "@/views/authResult")
  },

  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "test" */ "@/views/test")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
